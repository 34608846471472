import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-instructions" }
const _hoisted_2 = { class: "form-header" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.isInModal ? 'contact-in-modal' : '')
  }, [
    (!$setup.showUnlockedView)
      ? (_openBlock(), _createBlock($setup["ContactForm"], {
          key: 0,
          "form-settings-key": $props.formSettingsKey,
          "widget-data": $props.widgetData,
          "custom-data": $props.customData
        }, _createSlots({ _: 2 /* DYNAMIC */ }, [
          (!$setup.isNil($setup.customFormHeading) && $setup.customFormHeading != '')
            ? {
                name: "heading",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.customFormHeading), 1 /* TEXT */)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["form-settings-key", "widget-data", "custom-data"]))
      : (_openBlock(), _createBlock($setup["ContactPostSubmitStatement"], { key: 1 }))
  ], 2 /* CLASS */))
}